import * as _ from 'lodash'
import liff from '@line/liff'
import storage from '@/storage'
import { getCustomerByLineMid, getCustWaterProdList, getZipDist, getZipRoad } from '../api/api'
import axios from 'axios'

let isLiffInit = false

export default {
  install (Vue) {
    Vue.prototype.getUserCustProdId = async (LINEMid) => {
      const res01 = await getCustomerByLineMid({ LINEMid })
      if (res01.status === 200) {
        const CustId = _.get(res01, 'data.Data.CustId')
        if (!CustId) {
          return null
        }
        const res02 = await getCustWaterProdList({ CustId })
        const CustProdId = _.get(res02, 'data.Data.0.CustProdId')
        return CustProdId
      } else {
        return null
      }
    }

    Vue.prototype.getUserCustProdData = async (LINEMid) => {
      const res01 = await getCustomerByLineMid({ LINEMid })
      if (res01.status === 200) {
        const CustId = _.get(res01, 'data.Data.CustId')
        if (!CustId) {
          return null
        }
        const res02 = await getCustWaterProdList({ CustId })
        const CustProdData = _.get(res02, 'data.Data')
        return CustProdData
      } else {
        return null
      }
    }

    Vue.prototype.getUserLineId = ($route) => {
      return $route.query.mid || storage.getItem('LINEMid')
    }

    Vue.prototype.getUserCustId = async (LINEMid) => {
      const res01 = await getCustomerByLineMid({ LINEMid })
      const CustId = _.get(res01, 'data.Data.CustId')
      return CustId || ''
    }

    Vue.prototype.getZipDist = async () => {
      const res01 = await getZipDist()
      const list = _.get(res01, 'data.Data')
      return list
    }

    Vue.prototype.getZipRoad = async (ZipNo) => {
      const res01 = await getZipRoad({ ZipNo })
      const list = _.get(res01, 'data.Data')
      return list
    }

    Vue.prototype.liffInit = async (liffId) => {
      console.log('init')
      if (/localhost/.test(window.location.href) || isLiffInit) {
        return
      }

      isLiffInit = true

      await liff.init({
        liffId
      })

      if (liff.isLoggedIn()) {
        console.log('Login')
        const profile = await liff.getProfile()
        const decodedIDToken = liff.getDecodedIDToken()
        if (decodedIDToken) {
          profile.email = decodedIDToken.email || ''
          profile.phone = decodedIDToken.phone_number || '';
        }
        if (profile.email) {
          const url = 'https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/update-login-data'
          const body = {
            uid: profile.userId,
            email: profile.email,
            phone: profile.phone
          }
          await axios.post(url, body)
        }
        storage.setItem('LINEMid', profile.userId)
        console.log('set LineId')
        storage.setItem('userId', profile.userId)
        storage.setItem('displayName', profile.displayName)
        storage.setItem('pictureUrl', profile.pictureUrl || '')
        storage.setItem('email', profile.email || '')
        storage.setItem('phone', profile.phone)
        sessionStorage.setItem('uid', this.profile?.userId || '')
        localStorage.setItem('uid', this.profile?.userId || '')
        fetch('https://api-v2-dnz3lqp74q-de.a.run.app/log', {
          headers: {
            'content-type': 'application/json'
          },
          referrer: 'https://app-v2.aiii.ai/',
          referrerPolicy: 'strict-origin-when-cross-origin',
          body: JSON.stringify({
            site: 'sakura',
            userId: profile.userId,
            category: 'LineLiff',
            action: 'getUserProfileEmail',
            label: profile.email || ''
          }),
          method: 'POST',
          mode: 'cors',
          credentials: 'omit'
        })
      } else {
        liff.login({
          redirectUri: window.location.href
        })
      }
    }
  }
}
