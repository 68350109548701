import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCEBHGCjrb8sDSHbDYdvyVf8bbE1cB1DtQ',
  authDomain: 'aiii-project-sakura-water.firebaseapp.com',
  projectId: 'aiii-project-sakura-water',
  storageBucket: 'aiii-project-sakura-water.appspot.com',
  messagingSenderId: '605010676089',
  appId: '1:605010676089:web:f26d75dccb57d7381b07a8',
  measurementId: 'G-28L1XE73LE'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export default {
  firestore: firebaseApp.firestore()
}
